<template>
  <div class="content">
    <sub-title>슬롯</sub-title>
    <div class="casinoslot_panel">
      <money-chip-change-comp></money-chip-change-comp>
      <div class="items">
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'EVOPLAY'}}">
          <div class="pic" @click="getGameListByCode('EVOPLAY')">
            <img src="../../../assets/images/casino/s/01.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'PragmaticPlay'}}">
          <div class="pic" @click="getGameListByCode('PragmaticPlay')" alt="">
            <img src="../../../assets/images/casino/s/02.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'Habanero'}}">
          <div class="pic"  @click="getGameListByCode('Habanero')">
            <img src="../../../assets/images/casino/s/03.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'CQ9'}}">
          <div class="pic"  @click="getGameListByCode('CQ9')" alt="">
            <img src="../../../assets/images/casino/s/04.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'PlayStar'}}">
          <div class="pic"  @click="getGameListByCode('PlayStar')">
            <img src="../../../assets/images/casino/s/05.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'GameArt'}}">
          <div class="pic" @click="getGameListByCode('GameArt')">
            <img src="../../../assets/images/casino/s/06.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'Relax Gaming'}}">
          <div class="pic" @click="getGameListByCode('Relax Gaming')">
            <img src="../../../assets/images/casino/s/07.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'Booongo'}}">
          <div class="pic"  @click="getGameListByCode('Booongo')">
            <img src="../../../assets/images/casino/s/08.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'PlaySon'}}">
          <div class="pic" @click="getGameListByCode('PlaySon')">
            <img src="../../../assets/images/casino/s/09.jpg" alt="">
          </div>
        </router-link>
        <router-link class="itm" tag="div"  :to="{path: '/front/slotlist', query: {slotcode: 'Nolimit City'}}">
          <div class="pic" @click="getGameListByCode('Nolimit City')">
            <img src="../../../assets/images/casino/s/10.jpg" alt="">
          </div>
        </router-link>
      </div>
    </div>

    <my-unchecked-message-comp></my-unchecked-message-comp>
  </div>

</template>


<script>

    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";
    import {casinoMixin, checkLoginOnCreateMinxin, postionMixin} from "@/common/mixin";
    import sportsConst from "@/common/sportsConst";
    import SubTitle from "@/views/afront/zero/SubTitle.vue";
    import MoneyChipChangeComp from "@/views/afront/casino/MoneyChipChangeComp.vue";
    import MyUncheckedMessageComp from "@/views/afront/notice/MyUncheckedMessageComp.vue";
    import {getSlotMaintaining} from "@/network/casinoHonorRequest";
    export default {
      name: "SlotHonor",
      mixins: [postionMixin,casinoMixin,checkLoginOnCreateMinxin],
      components: {
        MyUncheckedMessageComp,
        MoneyChipChangeComp,
        SubTitle

      },
      data() {
        return {
          position: '슬롯',
        }
      },
      methods: {

      },
      created() {
        if (this.$store.state.isLogin) {
          getSlotMaintaining().then(res => {
            if (res.data.success) {
              if (!res.data.data) {
                this.$swal({
                  title: '슬롯 점검중입니다',
                  type: 'error',
                  confirmButtonText: '확인',
                  showCancelButton: false,
                  showConfirmButton: true
                })
              }
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                showCancelButton: false,
                showConfirmButton: true
              })
            }

          })
        }
      },
    }
</script>

<style scoped>
    @import url("../../../assets/css/front/afrontcasinoslot.css");
</style>